import {RoutePath} from '@/components/layout/navigation';
import {Trans, t} from '@lingui/macro';
import {createColumnHelper} from '@tanstack/react-table';
import {OtherRevenueOutputItem} from '@zentact/api/src/trpc/routers/reportingRouter';
import {
  CurrencyCode,
  LocaleCode,
  TableColumnSize,
  formatAmount,
  formatLocaleDate,
} from '@zentact/common';
import {DropDownMinimalMenuIcon, TruncatedText} from '@zentact/ui-tailwind';
import {Link, generatePath} from 'react-router-dom';

export const otherRevenueTypes = ['ANNUAL', 'MONTHLY', 'REFUND', 'CHARGEBACK'] as const;

export const displayOtherRevenueTypeMap = (otherRevenueType: string) => {
  const map: Record<string, string> = {
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    ANNUAL: t`Annual Fee`,
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    MONTHLY: t`Monthly Fee`,
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    REFUND: t`Refund Fee`,
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    CHARGEBACK: t`Chargeback Fee`,
  };
  return map[otherRevenueType];
};

const columnsHelper = createColumnHelper<OtherRevenueOutputItem>();

export const getOtherRevenueColumns = ({
  locale,
  currency,
  openDetailsPanel,
}: {
  locale: LocaleCode;
  currency: CurrencyCode;
  openDetailsPanel: (item: OtherRevenueOutputItem) => void;
}) => [
  columnsHelper.accessor('createdAt', {
    cell: props => formatLocaleDate(props.getValue(), 'shortWithTime'),
    header: () => <Trans>Date</Trans>,
    meta: {
      sort: {
        isSortable: true,
      },
    },
    size: TableColumnSize.S,
  }),
  columnsHelper.display({
    cell: props => <TruncatedText text={props.row.original.organizationName} />,
    header: () => <Trans>Organization</Trans>,
    id: 'organizationName',
    meta: {
      collapseAt: 'sm',
    },
  }),
  columnsHelper.display({
    cell: props => <TruncatedText text={props.row.original.businessName} />,
    header: () => <Trans>Merchant Account</Trans>,
    id: 'businessName',
    meta: {
      collapseAt: 'md',
    },
  }),
  columnsHelper.accessor('type', {
    cell: info => <div>{displayOtherRevenueTypeMap(info.getValue()) ?? info.getValue()}</div>,
    header: () => <Trans>Fee Type</Trans>,
    size: TableColumnSize.XS,
  }),
  columnsHelper.accessor('amount', {
    cell: ({row}) => formatAmount(row.original.amount, locale, currency),
    header: () => <Trans>Amount</Trans>,
    meta: {
      sort: {
        isSortable: true,
      },
      collapseAt: 'sm',
    },
    size: TableColumnSize.XS,
  }),
  columnsHelper.display({
    id: 'description',
    cell: ({row}) =>
      row.original.originalPaymentPspReferenceId ? (
        <Link
          className="text-sm text-primary-600"
          to={generatePath(RoutePath.PAYMENT_DETAILS, {
            pspReferenceId: row.original.originalPaymentPspReferenceId,
          })}
        >
          {row.original.originalPaymentPspReferenceId}
        </Link>
      ) : (
        row.original.description
      ),
    header: () => <Trans>Description</Trans>,
    meta: {
      visibleAt: 'sm',
      collapseAt: 'xl',
    },
    size: TableColumnSize.S,
  }),
  columnsHelper.display({
    id: 'actions',
    cell: props => (
      <div className="flex justify-center">
        <DropDownMinimalMenuIcon
          items={[{name: t`View Details`, onClick: () => openDetailsPanel(props.row.original)}]}
        />
      </div>
    ),
    size: TableColumnSize.ACTIONS,
  }),
];
