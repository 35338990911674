import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {
  CheckboxFilter,
  DashboardDatepicker,
  EntityPickerFilter,
  MerchantAccountsPickerFilter,
  ResetTableFiltersButton,
  getDashboardDatepickerDefaults,
} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {displayOtherRevenueTypeMap, otherRevenueTypes} from './other-revenue-list/columns';
import {OtherRevenueSearchParamsSchema} from './other-revenue-search-params-schema';

type Props = {
  typedSearchParams: OtherRevenueSearchParamsSchema;
  setTypedSearchParams: (params: OtherRevenueSearchParamsSchema) => void;
};

export const OtherRevenueFilters = ({typedSearchParams, setTypedSearchParams}: Props) => {
  const {orgsWithBoardedMerchants: organizationList, pspMerchantAccountName} = useStore();

  const dateValue =
    typedSearchParams.startDate && typedSearchParams.endDate
      ? {startDate: typedSearchParams.startDate, endDate: typedSearchParams.endDate}
      : getDashboardDatepickerDefaults();

  const merchantList =
    trpc.merchantAccount.getMerchantAccountsList
      .useQuery(
        {
          where: {
            ...(pspMerchantAccountName && {pspMerchantAccountName}),
          },
        },
        {enabled: !!pspMerchantAccountName}
      )
      .data?.rows.filter(
        ({merchantAccount}) => merchantAccount?.storeId && merchantAccount.businessName
      )
      .map(({merchantAccount}) => merchantAccount) ?? [];

  const handleFilterChange = useCallback(
    <K extends keyof OtherRevenueSearchParamsSchema>(
      key: K,
      value: OtherRevenueSearchParamsSchema[K]
    ) => {
      setTypedSearchParams({[key]: value, pageIndex: undefined});
    },
    [setTypedSearchParams]
  );
  const handleDateChange = useCallback(
    (dateRange: {startDate: Date; endDate: Date}) => {
      setTypedSearchParams({...dateRange, pageIndex: undefined});
    },
    [setTypedSearchParams]
  );

  return (
    <div className="flex flex-wrap items-center justify-end gap-2 lg:flex-nowrap">
      <div className="w-full">
        <ResetTableFiltersButton
          defaultFilters={{}}
          activeFilters={typedSearchParams}
          setFilters={setTypedSearchParams}
          ignoreKeys={[
            'sortColumnId',
            'sortValue',
            'pageIndex',
            'endDate', // count only startDate to show the date as a single filter
          ]}
        />
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        <EntityPickerFilter
          selected={typedSearchParams.organizationId}
          onChange={value => handleFilterChange('organizationId', value)}
          options={organizationList}
        />
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        <MerchantAccountsPickerFilter
          selectedMerchantAccount={typedSearchParams.merchantAccountId}
          onSelectMerchantAccount={value => handleFilterChange('merchantAccountId', value)}
          merchantAccountsOptions={merchantList}
          allLabel={'Merchant'}
        />
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        <CheckboxFilter
          label="Fee Type"
          selected={typedSearchParams.type}
          onChange={value => handleFilterChange('type', value)}
          options={otherRevenueTypes.map(type => ({
            element: (
              <div className="pl-2 text-gray-500 whitespace-nowrap">
                {displayOtherRevenueTypeMap(type) ?? type}
              </div>
            ),
            key: type,
          }))}
        />
      </div>
      <div className="w-full mt-4 shrink-0 lg:mt-0 lg:w-64">
        <DashboardDatepicker dateRange={dateValue} onChange={handleDateChange} />
      </div>
    </div>
  );
};
