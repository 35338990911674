import {trpc} from '@/api/trpcClient';
import {MerchantsRevenueList, OrganizationsRevenueList} from '@/components';
import {RoutePath} from '@/components/layout/navigation';
import {MerchantsList} from '@/pages/customers/merchants-list';
import {
  MerchantReviewPanel,
  MerchantReviewPanelProps,
} from '@/pages/customers/merchants-list/merchant-review-panel';
import {useStore} from '@/store';
import {ExclamationTriangleIcon} from '@heroicons/react/20/solid';
import {Plural, Trans, plural, t} from '@lingui/macro';
import {
  MerchantAccountPublicStatus,
  dashboardSearchParamsSchema,
  formatLocaleDate,
  formatPercentage,
  formatUrlQueryParams,
  initDashboardChartData,
} from '@zentact/common';
import {
  Breadcrumbs,
  DashboardDatepicker,
  EntityPicker,
  NavigationTabs,
  PaymentMethodsPieChart,
  Summary,
  SummaryItemDataType,
  SummaryItemPropType,
  TableSortValue,
  TransactionsChart,
  Typography,
  getDashboardDatepickerDefaults,
  useToggleWithData,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';
import {Link} from 'react-router-dom';

const primaryColor = '#45988D'; //TODO: get from config

export const DashboardPage = () => {
  const {
    orgsWithBoardedMerchants: organizationList,
    locale,
    pspMerchantAccountName,
    currency,
  } = useStore();
  const [organizationSort, setOrganizationSort] = useState<TableSortValue<string> | null>(null);
  const [merchantSort, setMerchantSort] = useState<TableSortValue<string> | null>({
    columnId: 'kycCompletedAt',
    value: 'asc',
  });

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(
    dashboardSearchParamsSchema
  );

  const selectedOrganization = typedSearchParams?.organizationId;
  const startDate = typedSearchParams?.startDate;
  const endDate = typedSearchParams?.endDate;
  const dateValue = startDate && endDate ? {startDate, endDate} : getDashboardDatepickerDefaults();

  const [selectedNavigationTab, setSelectedNavigationTab] = useState<'organizations' | 'merchants'>(
    'organizations'
  );

  const {
    data: merchantReviewRow,
    on: openMerchantReviewPanel,
    off: closeMerchantReviewPanel,
  } = useToggleWithData<MerchantReviewPanelProps['merchantAccountRow']>();

  const {data: summaryData, isLoading: summaryIsLoading} =
    trpc.payment.tenantPaymentSummary.useQuery({
      fromDate: dateValue.startDate,
      toDate: dateValue.endDate,
      organizationIds: selectedOrganization ? [selectedOrganization] : undefined,
      pspMerchantAccountName,
    });

  const {data: topOrgsDataFiltered, isLoading: topOrgsDataIsLoading} =
    trpc.payment.getOrganizationsRevenueList.useQuery(
      {
        fromDate: dateValue.startDate,
        toDate: dateValue.endDate,
        organizationIds: selectedOrganization ? [selectedOrganization] : undefined,
        pspMerchantAccountName,
        pageSize: 5,
      },
      {enabled: !selectedOrganization}
    );
  const {data: merchantsRevenueListData, isLoading: merchantsRevenueListLoading} =
    trpc.payment.getMerchantsRevenueList.useQuery({
      fromDate: dateValue.startDate,
      toDate: dateValue.endDate,
      organizationIds: selectedOrganization ? [selectedOrganization] : undefined,
      pspMerchantAccountName,
      pageSize: 5,
    });

  const awaitingApprovalMerchants = trpc.merchantAccount.getMerchantAccountsList.useQuery({
    ...(merchantSort?.columnId &&
      merchantSort.value && {orderBy: {[merchantSort.columnId]: merchantSort.value}}),
    where: {
      status: [MerchantAccountPublicStatus.AWAITING_REVIEW],
      ...(selectedOrganization && {organizationId: selectedOrganization}),
      pspMerchantAccountName,
    },
    pageIndex: 0,
    pageSize: 5,
  });

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {data: transactionsData, isLoading: transactionsIsLoading} =
    trpc.payment.paymentVolumeByDates.useQuery({
      organizationIds: selectedOrganization ? [selectedOrganization] : undefined,
      fromDate: dateValue.startDate,
      toDate: dateValue.endDate,
      pspMerchantAccountName,
      timezone: tz,
    });

  const achChargebacksCount = summaryData?.achChargebacksCount ?? 0;
  const cardChargebackCount = summaryData?.cardChargebackCount ?? 0;

  const summaryItems: SummaryItemPropType[] = [
    {
      title: t`Transaction Revenue`,
      value: summaryData?.totalRevenue ?? 0,
      type: SummaryItemDataType.CURRENCY,
      tooltipText: t`Transaction Revenue reflects the actual income received from transactions.`,
    },
    {
      title: t`Other Revenue`,
      value: summaryData?.otherRevenue ?? 0,
      type: SummaryItemDataType.CURRENCY,
      tooltipText: t`Other Revenue represents the total of monthly and annual fees, along with chargeback and refund fees, collected during the selected period.`,
    },
    {
      title: t`Volume`,
      value: summaryData?.totalVolume,
      type: SummaryItemDataType.CURRENCY,
      tooltipText: t`The cumulative value of all transactions completed within the selected period.`,
    },
    {
      title: t`Net Profit`,
      value: formatPercentage(
        summaryData?.totalVolume ?? 0,
        (summaryData?.totalRevenue ?? 0) + (summaryData?.otherRevenue ?? 0),
        locale
      ),
      type: SummaryItemDataType.STRING,
      tooltipText: t`Net Profit is calculated by dividing the revenue amount by the total transaction volume, expressed as a percentage.`,
    },
    {
      title: t`# of Payments`,
      value: summaryData?.paymentsCount ?? 0,
      type: SummaryItemDataType.NUMBER,
    },
    {
      title: summaryData?.refundsCount ? (
        <div className="flex gap-1">
          <Link
            to={`${RoutePath.PAYMENTS}?${formatUrlQueryParams({
              startDate: dateValue.startDate,
              endDate: dateValue.endDate,
              dateRangeFilterBy: 'refund',
              selectedOrganization,
            })}`}
            className="text-sm text-primary-600"
          >
            {summaryData.refundsCount}
          </Link>
          {plural(summaryData.refundsCount, {one: 'Refund', other: 'Refunds'})}
        </div>
      ) : (
        <Trans>0 Refunds</Trans>
      ),
      value: summaryData?.totalRefundedVolume,
      type: SummaryItemDataType.CURRENCY,
    },
    {
      title:
        achChargebacksCount + cardChargebackCount > 0 ? (
          <div className="flex gap-1">
            <Link
              to={`${RoutePath.PAYMENTS}?${formatUrlQueryParams({
                startDate: dateValue.startDate,
                endDate: dateValue.endDate,
                dateRangeFilterBy: 'chargeback',
                selectedOrganization,
              })}`}
              className="text-sm text-primary-600"
            >
              {achChargebacksCount + cardChargebackCount}
            </Link>
            {achChargebacksCount && cardChargebackCount
              ? t`Chargebacks (${cardChargebackCount} Cards, ${achChargebacksCount} ACH)`
              : cardChargebackCount
                ? plural(cardChargebackCount, {
                    one: 'Credit Card Chargeback',
                    other: 'Credit Card Chargebacks',
                  })
                : plural(achChargebacksCount, {
                    one: 'ACH Chargeback',
                    other: 'ACH Chargebacks',
                  })}
          </div>
        ) : (
          <Trans>0 Chargebacks</Trans>
        ),
      value: summaryData?.totalChargebacksVolume || 0,
      type: SummaryItemDataType.CURRENCY,
    },
    {
      title: t`Processing merchants`,
      value: summaryData?.processingMerchantAccounts || 0,
      type: SummaryItemDataType.NUMBER,
      tooltipText: t`Indicates the number of merchants that have processed at least one payment in the selected period.`,
      addonAfterValue: summaryData?.boardedMerchantAccounts ? (
        <span className="ml-1 text-xs font-semibold text-green-500">
          +{summaryData.boardedMerchantAccounts}
        </span>
      ) : null,
    },
  ];

  const [dataY, dataX] = transactionsData?.rows?.reduce(
    (acc, {volume, date}) => {
      acc[0].push(volume);
      acc[1].push(date);
      return acc;
    },
    [[] as number[], [] as string[]]
  ) || [[], []];

  const chartData = initDashboardChartData({
    title: t`Transactions`,
    dataX,
    dataY,
    primaryColor,
    currency,
    locale,
  });

  const handleOrganizationChange = useCallback(
    (organizationId?: string) => {
      setTypedSearchParams({organizationId});
    },
    [setTypedSearchParams]
  );

  const handleDateChange = useCallback(
    (dateRange: {startDate: Date; endDate: Date}) => {
      setTypedSearchParams(dateRange);
    },
    [setTypedSearchParams]
  );

  return (
    <>
      <Breadcrumbs currentDashboard={true} />
      <div className="flex flex-col items-center justify-between gap-2 pt-4 md:flex-row md:flex-wrap xl:flex-nowrap">
        <div className="w-full">
          <Typography variant="header-page">
            <Trans>For</Trans> {formatLocaleDate(dateValue.startDate, 'short')}
            {' - '}
            {formatLocaleDate(dateValue.endDate, 'short')}
          </Typography>
        </div>
        <div className="w-full mt-4 shrink-0 md:mt-0 md:w-60">
          <EntityPicker
            selected={selectedOrganization || undefined}
            onChange={handleOrganizationChange}
            options={organizationList}
          />
        </div>
        <div className="w-full mt-4 shrink-0 md:mt-0 md:w-64">
          <DashboardDatepicker dateRange={dateValue} onChange={handleDateChange} />
        </div>
      </div>
      <Summary
        summaryItems={summaryItems}
        isLoading={summaryIsLoading}
        locale={locale}
        currency={currency}
      />
      {!!awaitingApprovalMerchants.data?.rows.length && (
        <div className="mt-5 overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex items-center justify-between mb-5">
              <Typography variant="header-section">
                <div className="flex align-center">
                  <ExclamationTriangleIcon
                    className="w-10 h-10 mr-2 text-yellow-400"
                    aria-hidden="true"
                  />
                  {awaitingApprovalMerchants.data.pagination.total}{' '}
                  <Plural
                    value={awaitingApprovalMerchants.data.pagination.total}
                    one="Pending Merchant"
                    other="Pending Merchants"
                  />
                </div>
              </Typography>
              {!!awaitingApprovalMerchants.data?.pagination &&
                awaitingApprovalMerchants.data.pagination.pageCount > 1 && (
                  <Link
                    className="text-sm text-primary-600"
                    to={RoutePath.CUSTOMERS_AWAITING_APPROVAL}
                  >
                    <Trans>See all</Trans>
                  </Link>
                )}
            </div>
            {/* @ts-ignore fix TS2589: Type instantiation is excessively deep and possibly infinite. error  */}
            <MerchantsList
              merchantList={awaitingApprovalMerchants.data}
              sort={merchantSort}
              setSort={setMerchantSort}
              refetch={awaitingApprovalMerchants.refetch}
              mode="onlyPending"
              hideMerchantSearch
              hideAllSorting
              openReviewPanel={openMerchantReviewPanel}
            />
            <MerchantReviewPanel
              isOpen={!!merchantReviewRow}
              onCancel={closeMerchantReviewPanel}
              merchantAccountRow={merchantReviewRow}
              onSubmit={() => awaitingApprovalMerchants.refetch()}
            />
          </div>
        </div>
      )}
      <div className="mt-5">
        <NavigationTabs
          items={[
            ...(!selectedOrganization
              ? [
                  {
                    title: t`Organizations`,
                    key: 'organizations' as const,
                  },
                ]
              : []),
            {
              title: t`Merchants`,
              key: 'merchants',
            },
          ]}
          onChange={setSelectedNavigationTab}
          activeTabKey={!selectedOrganization ? selectedNavigationTab : 'merchants'}
        />
        <div className="overflow-hidden bg-white rounded-b-lg rounded-tr-lg shadow ">
          <div className="px-4 py-5 sm:px-6">
            {selectedNavigationTab === 'organizations' && !selectedOrganization ? (
              <div>
                <div className="flex items-center justify-between mb-5">
                  <Typography variant="header-section">
                    <Trans>Top Organizations</Trans>
                  </Typography>
                  <Link className="text-sm text-primary-600" to={RoutePath.CUSTOMERS_MERCHANTS}>
                    <Trans>See all</Trans>
                  </Link>
                </div>
                <OrganizationsRevenueList
                  data={topOrgsDataFiltered}
                  sort={organizationSort}
                  setSort={setOrganizationSort}
                  isLoading={topOrgsDataIsLoading}
                  currency={currency}
                />
              </div>
            ) : (
              <div>
                <div className="flex items-center justify-between mb-5">
                  <Typography variant="header-section">
                    <Trans>Top Merchants</Trans>
                  </Typography>
                  <Link className="text-sm text-primary-600" to={RoutePath.CUSTOMERS_MERCHANTS}>
                    <Trans>See all</Trans>
                  </Link>
                </div>
                <MerchantsRevenueList
                  data={merchantsRevenueListData}
                  sort={organizationSort}
                  setSort={setOrganizationSort}
                  isLoading={merchantsRevenueListLoading}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="grid gap-x-4 md:grid-cols-2">
        <TransactionsChart chartData={chartData} isLoading={transactionsIsLoading} />
        <div className="flex flex-col col-span-1 mt-5 overflow-hidden bg-white rounded-lg shadow content-stretch">
          <div className="px-4 pt-5 sm:px-6">
            <Typography variant="header-section" className="mb-5">
              <Trans>Payment Methods</Trans>
            </Typography>
          </div>
          <div className="flex flex-col items-center flex-grow justrify-center">
            <PaymentMethodsPieChart
              paymentMethods={summaryData?.paymentMethodStats ?? []}
              locale={locale}
              width={400}
              hideLabel
            />
          </div>
        </div>
      </div>
    </>
  );
};
