import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useDefaultSplitConfigurationGroup, useLocale} from '@/hooks';
import {MakeDefaultConfirmation} from '@/pages/configure/fee-groups/split-configuration-groups-list/make-default-confirmation/make-default-confirmation';
import {OrganizationFormData} from '@/pages/customers/organization-form/organization-form';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {
  ErrorCode,
  SplitConfigurationGroupPublicStatus,
  fromMinorUnits,
  isFormattedTrpcError,
  toMinorUnits,
} from '@zentact/common';
import type {PricingModel} from '@zentact/db';
import {Breadcrumbs, Button, Typography, useNotification} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {DeepPartial, useForm} from 'react-hook-form';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {
  FeeGroupForm,
  SplitConfigurationGroupFormData,
  getSplitConfigurationGroupFormSchema,
} from './fee-group-form';

const getBreadCrumbs = () => [
  {name: t`Configure`, href: RoutePath.CONFIGURE, current: false, disabled: true},
  {name: t`Fee Groups`, href: RoutePath.FEE_GROUPS, current: false},
  {name: t`Create New Fee Group`, href: RoutePath.FEE_GROUP_CREATE, current: true},
];

type ReturnToState = {
  inviteOrganizationFormData?: DeepPartial<OrganizationFormData>;
};

export const FeeGroupCreatePage = () => {
  const {pspMerchantAccountName, currency, tenant} = useStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const {defaultSplitConfigurationGroup} =
    useDefaultSplitConfigurationGroup(pspMerchantAccountName);
  const {returnTo, returnToState} = (location.state ?? {}) as {
    returnTo?: string;
    returnToState?: ReturnToState;
  };

  const sourceFeeGroupId = useMemo(() => searchParams.get('sourceFeeGroupId'), [searchParams]);
  const isDuplicating = !!sourceFeeGroupId;
  const [makeDefaultConfirmationOpen, setMakeDefaultConfirmationOpen] = useState(false);
  const {data: defaultSplitConfigurationData} = trpc.splitConfigurationGroup.byId.useQuery(
    searchParams.get('sourceFeeGroupId') ?? '',
    {enabled: !!sourceFeeGroupId}
  );

  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {locale} = useLocale();

  const isACHPaymentMethodEnabled = useMemo(
    () => tenant.checkoutConfiguration?.paymentMethods.includes('ACH'),
    [tenant.checkoutConfiguration?.paymentMethods]
  );

  const form = useForm<SplitConfigurationGroupFormData>({
    resolver: zodResolver(
      getSplitConfigurationGroupFormSchema(locale, currency, isACHPaymentMethodEnabled)
    ),
    defaultValues: {
      isActive: true,
      cardPricingModel: 'FLAT' as PricingModel,
      achFixedOrPercentage: 'FIXED',
      chargebackSplitModel: 'TENANT_PAY',
      refundSplitModel: 'REVERSE_PAYMENT_SPLIT',
      refundFeesModel: 'TENANT_PAY',
      tenantIntervalFees: [{}],
    },
  });

  useEffect(() => {
    if (!defaultSplitConfigurationData) {
      return;
    }

    const {
      name,
      status,
      cardPricingModel,
      cardSplitFixed,
      cardSplitPercentage,
      achSplitFixed,
      achSplitPercentage,
      interacSplitFixed,
      amexSplitFixed,
      amexSplitPercentage,
      chargebackSplitModel,
      additionalChargebackFee,
      refundSplitModel,
      refundFeesModel,
      additionalRefundFee,
      perDeviceFee,
      tenantIntervalFees,
    } = defaultSplitConfigurationData;

    form.reset({
      name: name ? getDuplicateFeeGroupName(name) : '',
      isActive: status === SplitConfigurationGroupPublicStatus.ACTIVE || true,
      cardPricingModel: cardPricingModel ?? 'FLAT',
      cardSplitFixed: cardSplitFixed ? fromMinorUnits(cardSplitFixed, currency) : undefined,
      cardSplitPercentageFlat:
        cardPricingModel === 'FLAT' && cardSplitPercentage
          ? fromMinorUnits(cardSplitPercentage, currency)
          : undefined,
      cardSplitPercentageCostPlus:
        cardPricingModel === 'COST_PLUS' && cardSplitPercentage ? cardSplitPercentage : undefined,
      achFixedOrPercentage: achSplitFixed ? 'FIXED' : 'PERCENTAGE',
      achSplitFixed: achSplitFixed ? fromMinorUnits(achSplitFixed, currency) : undefined,
      achSplitPercentage: achSplitPercentage
        ? fromMinorUnits(achSplitPercentage, currency)
        : undefined,
      chargebackSplitModel: chargebackSplitModel ?? 'TENANT_PAY',
      additionalChargebackFee: additionalChargebackFee
        ? fromMinorUnits(additionalChargebackFee, currency)
        : undefined,
      interacSplitFixed: interacSplitFixed
        ? fromMinorUnits(interacSplitFixed, currency)
        : undefined,
      amexSplitFixed: amexSplitFixed ? fromMinorUnits(amexSplitFixed, currency) : undefined,
      amexSplitPercentage: amexSplitPercentage
        ? fromMinorUnits(amexSplitPercentage, currency)
        : undefined,
      refundSplitModel: refundSplitModel ?? 'REVERSE_PAYMENT_SPLIT',
      refundFeesModel: refundFeesModel ?? 'TENANT_PAY',
      additionalRefundFee: additionalRefundFee
        ? fromMinorUnits(additionalRefundFee, currency)
        : undefined,
      perDeviceFee: perDeviceFee ? fromMinorUnits(perDeviceFee, currency) : undefined,
      tenantIntervalFees: tenantIntervalFees
        ? tenantIntervalFees.map(fee => ({
            name: fee.name ?? '',
            amount: fromMinorUnits(fee.amount, currency),
            period: fee.period,
            annualFeeCollectMonth: fee.annualFeeCollectMonth
              ? fee.annualFeeCollectMonth.toString()
              : undefined,
          }))
        : [{}],
    });
  }, [defaultSplitConfigurationData]);

  const {setError, setFocus, handleSubmit, watch} = form;

  const createSplitConfigurationGroupMutation = trpc.splitConfigurationGroup.create.useMutation({
    onSuccess: created => {
      showSuccessNotification(
        t`Split Configuration Group is created`,
        t`Now you can apply this group to your new merchants`
      );
      navigate(returnTo ? returnTo : RoutePath.FEE_GROUPS, {
        state: updateReturnToStateWithCreatedSplitConfigurationGroup(returnToState, created.id),
      });
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      if (errorCode === ErrorCode.SPLIT_CONFIGURATION_GROUP_ALREADY_EXISTS) {
        setError('name', {
          type: 'manual',
          message: t`Fee Group with the same name already exists`,
        });
        setFocus('name');

        return;
      }

      showErrorNotification(
        t`Failed to create split configuration group`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const onSubmit = useCallback(
    (data: SplitConfigurationGroupFormData) => {
      if (data.isDefault && !makeDefaultConfirmationOpen) {
        setMakeDefaultConfirmationOpen(true);
        return;
      }

      createSplitConfigurationGroupMutation.mutate({
        name: data.name,
        isDefault: data.isDefault,
        pspMerchantAccountName,
        status: data.isActive
          ? SplitConfigurationGroupPublicStatus.ACTIVE
          : SplitConfigurationGroupPublicStatus.INACTIVE,
        cardPricingModel: data.cardPricingModel,
        cardSplitPercentage:
          data.cardPricingModel === 'FLAT'
            ? data.cardSplitPercentageFlat
              ? toMinorUnits(data.cardSplitPercentageFlat, currency)
              : 0
            : data.cardSplitPercentageCostPlus
              ? data.cardSplitPercentageCostPlus
              : 0,
        cardSplitFixed: data.cardSplitFixed ? toMinorUnits(data.cardSplitFixed, currency) : 0,
        achSplitPercentage:
          data.achFixedOrPercentage === 'PERCENTAGE' && data.achSplitPercentage
            ? toMinorUnits(data.achSplitPercentage, currency)
            : 0,
        achSplitFixed:
          data.achFixedOrPercentage === 'FIXED' && data.achSplitFixed
            ? toMinorUnits(data.achSplitFixed, currency)
            : 0,
        interacSplitFixed: data.interacSplitFixed
          ? toMinorUnits(data.interacSplitFixed, currency)
          : 0,
        amexSplitFixed: data.amexSplitFixed ? toMinorUnits(data.amexSplitFixed, currency) : 0,
        amexSplitPercentage: data.amexSplitPercentage
          ? toMinorUnits(data.amexSplitPercentage, currency)
          : 0,
        // TODO: clean up after 1.9.0
        chargebackSplitModel: data.chargebackSplitModel,
        chargebackFeesModel: data.chargebackSplitModel,
        additionalChargebackFee: data.additionalChargebackFee
          ? toMinorUnits(data.additionalChargebackFee, currency)
          : 0,
        refundSplitModel: data.refundSplitModel,
        refundFeesModel: data.refundFeesModel,
        additionalRefundFee: data.additionalRefundFee
          ? toMinorUnits(data.additionalRefundFee, currency)
          : 0,
        perDeviceFee: data.perDeviceFee && toMinorUnits(data.perDeviceFee, currency),
        tenantIntervalFees: data.tenantIntervalFees
          .filter(fee => fee.amount && fee.name)
          .map(fee => ({
            // biome-ignore lint/style/noNonNullAssertion: checked in the upper filter
            name: fee.name!,
            // biome-ignore lint/style/noNonNullAssertion: checked in the upper filter
            amount: toMinorUnits(fee.amount!, currency)!,
            period: fee.period,
            annualFeeCollectMonth: fee.annualFeeCollectMonth
              ? Number(fee.annualFeeCollectMonth)
              : undefined,
          })),
      });
    },
    [makeDefaultConfirmationOpen]
  );

  const splitConfigurationGroupName = watch('name');

  return (
    <div>
      <Breadcrumbs pages={getBreadCrumbs()} />
      <Typography variant="header-page" className="pt-4">
        {isDuplicating ? <Trans>Duplicate Fee Group</Trans> : <Trans>Create New Fee Group</Trans>}
      </Typography>
      <div className="py-3 text-sm text-gray-700">
        <Trans>
          Set up how to charge your merchants. You can have a default set of fees and create custom
          configurations for different circumstances. You will apply these when adding a new
          merchant. Monthly fees will apply to all merchants onboarded by the 20th day of the month.
        </Trans>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FeeGroupForm form={form} />
        <footer className="flex justify-end mt-8">
          <Button
            size="lg"
            type="submit"
            className="w-fit"
            disabled={createSplitConfigurationGroupMutation.isLoading}
            isLoading={createSplitConfigurationGroupMutation.isLoading}
          >
            <Trans>Create Fee Group</Trans>
          </Button>
        </footer>
      </form>
      <MakeDefaultConfirmation
        open={makeDefaultConfirmationOpen}
        setOpen={setMakeDefaultConfirmationOpen}
        handleMakeDefault={() => {
          onSubmit(form.getValues());
        }}
        isLoading={createSplitConfigurationGroupMutation.isLoading}
        defaultSplitConfigurationGroup={defaultSplitConfigurationGroup}
        splitConfigurationGroupName={splitConfigurationGroupName}
      />
    </div>
  );
};

const getDuplicateFeeGroupName = (sourceSplitConfigurationGroupName: string) => {
  const copyPattern: RegExp = /\(Copy(?: (\d+))?\)$/;

  // Check if groupName matches the pattern
  const match: RegExpExecArray | null = copyPattern.exec(sourceSplitConfigurationGroupName);

  if (!match) {
    // If pattern is not present, append "(Copy)"
    return `${sourceSplitConfigurationGroupName} (Copy)`;
  }

  // Extract the number part, if present
  const copyNumber: string | undefined = match[1];

  if (copyNumber === undefined) {
    // If no number is present, it's the second copy
    return sourceSplitConfigurationGroupName.replace(' (Copy)', ' (Copy 2)');
  }
  // If a number is present, increment it
  const incrementedNumber: number = parseInt(copyNumber, 10) + 1;
  return sourceSplitConfigurationGroupName.replace(copyPattern, `(Copy ${incrementedNumber})`);
};

const updateReturnToStateWithCreatedSplitConfigurationGroup = (
  returnToState: ReturnToState | undefined,
  splitConfigurationGroupId: string
) => {
  if (returnToState?.inviteOrganizationFormData) {
    const {inviteOrganizationFormData} = returnToState;
    return {
      inviteOrganizationFormData: {
        ...inviteOrganizationFormData,
        splitConfigurationGroupId,
      },
    };
  }
  return returnToState;
};
