import {useStore} from '@/store';
import {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

export const useRedirectWhenPspMerchantAccountChanges = (redirectPath: string) => {
  const {pspMerchantAccountName} = useStore();
  const previousValueRef = useRef(pspMerchantAccountName);
  const navigate = useNavigate();

  useEffect(() => {
    const previousValue = previousValueRef.current;

    if (
      previousValue !== undefined &&
      pspMerchantAccountName !== undefined &&
      previousValue !== pspMerchantAccountName
    ) {
      navigate(redirectPath);
    }

    // Update the ref with the current value for the next render
    previousValueRef.current = pspMerchantAccountName;
  }, [pspMerchantAccountName, redirectPath, navigate]);
};
