import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {MerchantChargebackStatsOutputItem} from '@zentact/api/src/trpc/routers/reportingRouter';
import {
  Breadcrumbs,
  Button,
  EntityPicker,
  ResetTableFiltersButton,
  Typography,
  createSetPaginationCallback,
  useNotification,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {z} from 'zod';
import {
  MerchantChargebackStatsList,
  exportToCsvMerchantChargebackStats,
} from './merchant-chargeback-stats-list';
import {chargebackRatioWarningThreshold} from './merchant-chargeback-stats-list/columns';

const breadcrumbs = () => [
  {name: t`Risk`, href: RoutePath.RISK, current: false},
  {name: t`Merchants Credit Card Chargebacks`, href: '#', current: true},
];
const pageSize = 25;

export const MerchantChargebacks = () => {
  const {orgsWithBoardedMerchants: organizationList, pspMerchantAccountName, currency} = useStore();
  const navigate = useNavigate();
  const {i18n} = useLingui();

  const searchParamsSchema = z.object({
    organizationId: z.string().nullable().optional(),
    businessName: z.string().nullable().optional(),
    sortColumnId: z.string().nullable().optional(),
    sortValue: z.literal('asc').or(z.literal('desc')).nullable().optional(),
    pageIndex: z.string().optional(),
  });

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(searchParamsSchema);

  const organizationId = typedSearchParams?.organizationId;
  const businessName = typedSearchParams?.businessName;
  const sort =
    typedSearchParams.sortColumnId && typedSearchParams.sortValue
      ? {
          columnId: typedSearchParams.sortColumnId,
          value: typedSearchParams.sortValue,
        }
      : null;

  const pagination = {
    pageIndex: typedSearchParams.pageIndex ? Number(typedSearchParams.pageIndex) : 0,
    pageSize,
  };

  const setPagination = createSetPaginationCallback(pagination, setTypedSearchParams);

  const {showSuccessNotification, showErrorNotification} = useNotification();
  const merchantList = trpc.reporting.getMerchantChargebackStats.useQuery(
    {
      ...pagination,
      ...(sort && {orderBy: {columnId: sort.columnId, value: sort.value}}),
      where: {
        ...(organizationId && {organizationId}),
        ...(businessName && {businessName}),
        ...(pspMerchantAccountName && {pspMerchantAccountName}),
      },
    },
    {keepPreviousData: true, refetchOnWindowFocus: false, refetchOnReconnect: false}
  );

  const handleOpenMerchantDetails = useCallback(
    (row: MerchantChargebackStatsOutputItem) => {
      navigate(
        generatePath(RoutePath.MERCHANT_DETAILS, {merchantAccountId: row.merchantAccountId})
      );
    },
    [navigate, generatePath]
  );

  useEffect(() => {
    setTypedSearchParams({pageIndex: undefined});
  }, [
    typedSearchParams.sortValue,
    typedSearchParams.sortColumnId,
    typedSearchParams.organizationId,
    typedSearchParams.businessName,
  ]);

  const handleSelectOrganization = useCallback(
    (organizationId?: string) => {
      setTypedSearchParams({organizationId, pageIndex: undefined});
    },
    [setTypedSearchParams]
  );

  const trpcContext = trpc.useUtils();
  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullMerchantsList = await trpcContext.reporting.getMerchantChargebackStats.fetch({
        ...(sort && {orderBy: {columnId: sort.columnId, value: sort.value}}),
        where: {
          ...(organizationId && {organizationId}),
          ...(businessName && {businessName}),
          ...(pspMerchantAccountName && {pspMerchantAccountName}),
        },
      });
      exportToCsvMerchantChargebackStats(fullMerchantsList, i18n, currency);
      showSuccessNotification(t`Merchants .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Merchants .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [
    setCsvLoading,
    trpcContext,
    exportToCsvMerchantChargebackStats,
    sort,
    typedSearchParams,
    i18n,
  ]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="flex flex-col flex-wrap gap-2 pt-4 lg:justify-between lg:items-center lg:flex-row">
        <Typography variant="header-page" className="flex md:whitespace-nowrap">
          <Trans>Merchants Credit Card Chargebacks</Trans>
        </Typography>
        <div className="flex flex-col gap-2 lg:items-center lg:flex-row">
          <ResetTableFiltersButton
            defaultFilters={{businessName: undefined, organizationId: undefined}}
            activeFilters={{businessName, organizationId}}
            setFilters={setTypedSearchParams}
          />
          <div className="w-full shrink-0 lg:w-60">
            <EntityPicker
              selected={organizationId || ''}
              onChange={handleSelectOrganization}
              options={organizationList}
            />
          </div>
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit whitespace-nowrap max-lg:w-full"
            isLoading={isCsvLoading}
            onClick={handleCsvExport}
            disabled={!merchantList.data || merchantList.data.rows.length === 0}
          >
            <Trans>Export to CSV</Trans>
          </Button>
        </div>
      </div>
      <div className="mt-4 text-sm leading-6 text-gray-500">
        <Trans>
          This report shows a list of merchants with the percentage of card chargebacks relative to
          the number of transactions. Usually, the percentage should not exceed a threshold of{' '}
          {chargebackRatioWarningThreshold}%. ACH payments are not included in this statistic.
        </Trans>
      </div>
      <div className="mt-4">
        <MerchantChargebackStatsList
          merchantList={merchantList.data}
          filters={typedSearchParams || {}}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={newSort => {
            setTypedSearchParams({
              sortColumnId: newSort?.columnId,
              sortValue: newSort?.value,
            });
          }}
          pagination={pagination}
          onPaginationChange={setPagination}
          openDetails={handleOpenMerchantDetails}
          isLoading={merchantList.isLoading}
          currency={currency}
        />
      </div>
    </div>
  );
};
