import {trpc} from '@/api/trpcClient';
import {PencilSquareIcon} from '@heroicons/react/24/outline';
import {zodResolver} from '@hookform/resolvers/zod';
import {t} from '@lingui/macro';
import {ErrorCode, isFormattedTrpcError} from '@zentact/common';
import {
  AlertOverlayWithConfirmation,
  InputText,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import z from 'zod';

export const getOrganizationNameSchema = () =>
  z.object({
    organizationName: z.string().min(1, t`Organization name is required`),
  });

export type OrganizationNameData = z.infer<ReturnType<typeof getOrganizationNameSchema>>;

type Props = {
  organizationName: string;
  pspMerchantAccountName: string;
  organizationId: string;
  refetch: () => void;
};

export const EditOrganizationName = ({
  organizationName,
  organizationId,
  pspMerchantAccountName,
  refetch,
}: Props) => {
  const [open, setOpen] = useState(false);
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const form = useForm<OrganizationNameData>({
    resolver: zodResolver(getOrganizationNameSchema()),
    defaultValues: {
      organizationName,
    },
  });
  const {
    register,
    formState: {errors},
    handleSubmit,
    setError,
    clearErrors,
    reset,
  } = form;

  useEffect(() => {
    reset({organizationName});

    if (!open) {
      clearErrors();
    }
  }, [organizationName, open]);

  const updateOrganizationName = trpc.organization.updateOrganizationName.useMutation({
    onSuccess: () => {
      showSuccessNotification(
        t`Organization name updated`,
        t`The organization name has been updated.`
      );
      setOpen(false);
      refetch();
      reset();
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      if (errorCode === ErrorCode.ORGANIZATION_ALREADY_EXISTS) {
        setError('organizationName', {
          type: 'manual',
          message: t`An organization with that name already exists, please choose a unique name.`,
        });

        return;
      }

      showErrorNotification(
        t`Failed to update organization name`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const onSubmit = useCallback(
    (data: OrganizationNameData) => {
      updateOrganizationName.mutate({
        newOrganizationName: data.organizationName,
        pspMerchantAccountName,
        organizationId,
      });
    },
    [organizationId]
  );

  return (
    <>
      <div className="flex items-center justify-end">
        <PencilSquareIcon
          className="w-5 h-5 cursor-pointer text-primary-600"
          onClick={() => setOpen(true)}
        />
      </div>
      <AlertOverlayWithConfirmation
        open={open}
        setOpen={setOpen}
        disableConfirmButton={false}
        handleAction={handleSubmit(onSubmit)}
        mode="error"
        localeText={{
          title: t`Are you sure you want to rename this organization?`,
          description: t`Renaming this will change the name across the experience for all users.`,
          confirm: t`Rename`,
          cancel: t`Cancel`,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="px-4 pb-4 bg-white">
          <div className="pb-2 mt-2">
            <p className="text-sm">Organization Name</p>
          </div>
          <div className="">
            <InputText
              {...register('organizationName', {required: true})}
              hasError={Boolean(errors.organizationName)}
              placeholder={t`e.g Acme Corporation`}
            />
          </div>
          <ValidationError isVisible={Boolean(errors.organizationName)}>
            {errors.organizationName?.message}
          </ValidationError>
        </form>
      </AlertOverlayWithConfirmation>
    </>
  );
};
