import {CurrencyCode, LocaleCode, formatAmount} from '@zentact/common';
import {tailwindDefaultBreakpoints} from '@zentact/tailwind-config/tailwind-default-breakpoints';
import {QuestionTooltip, SkeletonLoader} from '@zentact/ui-tailwind';
import {useMediaQuery} from 'usehooks-ts';
import {splitArrayElementsInRows} from './split-array-elements-in-rows';

export enum SummaryItemDataType {
  CURRENCY = 'currency',
  NUMBER = 'number',
  STRING = 'string',
}

export type SummaryItemPropType = {
  title: React.ReactNode;
  value?: string | number;
  type: SummaryItemDataType;
  tooltipText?: React.ReactNode;
  addonAfterValue?: React.ReactNode | null;
};

export function Summary({
  summaryItems,
  isLoading,
  locale,
  currency,
}: {
  summaryItems: SummaryItemPropType[];
  isLoading?: boolean;
  locale: LocaleCode;
  currency: CurrencyCode;
}) {
  const isDesktop = useMediaQuery(`(min-width: ${tailwindDefaultBreakpoints.md})`);
  const itemsInARow = isDesktop ? 4 : 2;
  const itemsByRows = splitArrayElementsInRows(summaryItems, itemsInARow);

  return (
    <div className="mt-5 bg-white rounded-lg shadow">
      <div className="divide-y divide-gray-200">
        {itemsByRows.map((items, rowId) => (
          <div
            className="grid grid-cols-2 overflow-hidden divide-x divide-gray-200 md:grid-cols-4"
            key={`summaryRow-${rowId}`}
          >
            {items.map(({title, value, type, tooltipText, addonAfterValue}, i) => (
              <div key={`summary-${i}`} className="col-span-1 px-6 py-5">
                <SkeletonLoader
                  isLoading={Boolean(isLoading)}
                  className="h-3 min-h-[100px] max-w-xs ring-gray-400/5 sm:rounded-xl col-span-1 md:min-h-full"
                >
                  <dd className="flex mt-1">
                    <div className="flex items-center text-2xl font-semibold">
                      {type === SummaryItemDataType.CURRENCY
                        ? formatAmount(Number(value), locale, currency)
                        : value}
                    </div>
                    {!!addonAfterValue && addonAfterValue}
                  </dd>
                  <div className="flex items-center gap-x-2">
                    <dt className="text-sm font-normal text-gray-600">{title}</dt>
                    {tooltipText && <QuestionTooltip text={tooltipText} />}
                  </div>
                </SkeletonLoader>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
