import {I18n} from '@lingui/core';
import {t} from '@lingui/macro';
import {OtherRevenueOutput} from '@zentact/api/src/trpc/routers/reportingRouter';
import {
  CSV_EXPORT_FULL_DATE_FORMAT,
  CurrencyCode,
  LocaleCode,
  formatAmountWithoutCurrency,
  formatLocaleDate,
} from '@zentact/common';
import {ExportToCsv} from '@zentact/ui-tailwind';
import {DateTime} from 'luxon';
import {displayOtherRevenueTypeMap} from './other-revenue-list/columns';

export const exportOtherRevenueCSV = (
  list: OtherRevenueOutput,
  startDate: Date,
  endDate: Date,
  i18n: I18n,
  currency: CurrencyCode
) => {
  const dates = `${formatLocaleDate(startDate, 'short')}-${formatLocaleDate(endDate, 'short')}`
    .toLowerCase()
    .replaceAll(' ', '-');
  const exporter = new ExportToCsv({
    filename: `other-revenue-report-${dates}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!list.rows.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    list.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`PSP Merchant Account`]: row.pspMerchantAccountName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Date`]: DateTime.fromISO(row.createdAt).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Merchant Account`]: row.businessName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Organization`]: row.organizationName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Amount`]: formatAmountWithoutCurrency(row.amount, i18n.locale as LocaleCode, currency),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Type`]: displayOtherRevenueTypeMap(row.type) ?? row.type,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Description`]: row.description ?? 'N/A',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment PSP Reference`]: row.originalPaymentPspReferenceId ?? 'N/A',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Currency`]: currency,
    }))
  );
};
