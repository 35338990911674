import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {useMemo} from 'react';

export const useSplitConfigurationGroups = (isActiveOnly = true) => {
  const {pspMerchantAccountName} = useStore();
  const {data: splitConfigurationGroups} = trpc.splitConfigurationGroup.list.useQuery(
    {
      where: {
        ...(isActiveOnly ? {status: ['ACTIVE']} : {}),
        ...(pspMerchantAccountName && {pspMerchantAccountName}),
      },
    },
    {
      refetchOnWindowFocus: true,
    }
  );
  const defaultSplitConfigurationGroup = useMemo(() => {
    if (!splitConfigurationGroups?.rows?.length) {
      return;
    }

    return splitConfigurationGroups.rows.find(group => group.isDefault);
  }, [splitConfigurationGroups]);

  return {
    splitConfigurationGroups: splitConfigurationGroups?.rows ?? [],
    defaultSplitConfigurationGroup,
  };
};

export const useDefaultSplitConfigurationGroup = (pspMerchantAccountName?: string) => {
  const {data: defaultSplitConfigurationGroup, refetch} =
    trpc.splitConfigurationGroup.getDefaultSplitConfigurationGroup.useQuery(
      {
        // biome-ignore lint/style/noNonNullAssertion:Checked in the enabled prop
        pspMerchantAccountName: pspMerchantAccountName!,
      },
      {enabled: !!pspMerchantAccountName}
    );

  return {defaultSplitConfigurationGroup, refetch};
};
