import {
  BanknotesIcon,
  BuildingOffice2Icon,
  BuildingStorefrontIcon,
  ChartPieIcon,
  CodeBracketIcon,
  Cog6ToothIcon,
  PresentationChartLineIcon,
  ShieldCheckIcon,
  TableCellsIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import {t} from '@lingui/macro';

export enum RoutePath {
  ERROR = '/error',
  SESSION_EXPIRED = '/session-expired',
  CUSTOMERS = '/customers',
  ORGANIZATIONS = '/customers/organizations',
  CUSTOMERS_MERCHANTS = '/customers/merchants',
  CUSTOMERS_AWAITING_APPROVAL = '/customers/awaiting-approval',
  CUSTOMERS_ACCOUNT_ACTIVITY = '/customers/account-activity',
  CUSTOMERS_PAYOUTS = '/customers/payouts',
  MERCHANT_DETAILS = '/customers/merchants/:merchantAccountId',
  STATEMENTS = '/customers/statements',
  DASHBOARD = '/dashboard',
  RISK = '/risk',
  RISK_MERCHANT_STOPPED_PROCESSING = '/risk/merchant-stopped-processing',
  RISK_MERCHANT_CHARGREBACKS = '/risk/merchant-chargebacks',
  CONFIGURE = '/configure',
  SUPPORT = '/support',
  BRAND = '/configure/brand',
  WEBHOOKS = '/configure/webhooks',
  EMAIL = 'configure/email',
  API_KEYS = '/configure/api-keys',
  FEE_GROUPS = '/configure/fee-groups',
  FEE_GROUP_CREATE = '/configure/fee-groups/create',
  FEE_GROUP_DETAILS = '/configure/fee-groups/:splitConfigurationGroupId',
  SALES_DAY = '/configure/sales-day',
  RESERVE = '/configure/reserve',
  MERCHANT_REGISTRATIONS = 'merchant_registrations',
  COMMINUCATION = 'communication',
  FINANCE = '/finance',
  FINANCE_ACCOUNT_ACTIVITY = '/finance/account-activity',
  FINANCE_PAYOUTS = '/finance/payouts',
  REPORTS = '/reports',
  REPORTS_PROFITABILITY = '/reports/profitability',
  REPORTS_INTERCHANGE = '/reports/interchange',
  REPORTS_OTHER_REVENUE = '/reports/other-revenue',
  REPORTS_RISK = '/reports/risk',
  REPORTS_FEES = '/reports/fees',
  REPORTS_MONTH_TO_MONTH_PERFOMANCE = '/reports/month-to-month-perfomance',
  REPORTS_EFFECTIVE_DISCOUNT_RATE = '/reports/effective-discount-rate',
  REPORTS_ACCOUNT_PERFOMANCE = '/reports/account-perfomance',
  REPORTS_CHARGEBACK = '/reports/chargeback',
  MERCHANTS = '/merchants',
  TRANSACTIONS = '/transactions',
  PAYMENTS = '/transactions/payments',
  PAYMENT_DETAILS = '/transactions/payments/:pspReferenceId',
  DISPIUTES = '/transactions/disputes',
  REFUNDS = '/refunds',
  PAYOUTS = '/payouts',
  USERS = '/users',
  ACTIVE_USERS = '/users/active',
  INVITED_USERS = '/users/invited',
  TERMINALS = '/terminals',
  TERMINALS_MANAGE = '/terminals/manage',
  USER_SIGNUP = '/signup',
  USER_LOGIN = '/login',
  USER_LOGOUT = '/logout',
  AUTH_CALLBACK = '/auth/callback',
  USER_PROFILE = '/user/:id/profile',
}

export const navigation = ({
  disableAddingMerchantInPortal,
  enableTerminals,
  showInterchangeReportPage,
}: {
  disableAddingMerchantInPortal?: boolean;
  enableTerminals?: boolean;
  showInterchangeReportPage?: boolean;
}) => [
  {name: t`Dashboard`, href: RoutePath.DASHBOARD, icon: ChartPieIcon},
  {
    name: t`Customers`,
    href: RoutePath.CUSTOMERS,
    icon: BuildingOffice2Icon,
    children: [
      {name: t`Organizations`, href: RoutePath.ORGANIZATIONS},
      {name: t`Merchants`, href: RoutePath.CUSTOMERS_MERCHANTS},
      ...(!disableAddingMerchantInPortal
        ? [{name: t`Awaiting Approval`, href: RoutePath.CUSTOMERS_AWAITING_APPROVAL}]
        : []),
      {name: t`Account Activity`, href: RoutePath.CUSTOMERS_ACCOUNT_ACTIVITY},
      {name: t`Statements`, href: RoutePath.STATEMENTS},
      {name: t`Payouts`, href: RoutePath.CUSTOMERS_PAYOUTS},
    ],
  },
  {
    name: t`Transactions`,
    href: RoutePath.TRANSACTIONS,
    icon: TableCellsIcon,
    children: [
      {name: t`Payments`, href: RoutePath.PAYMENTS},
      {name: t`Disputes`, href: RoutePath.DISPIUTES},
    ],
  },
  {
    name: t`Finance`,
    href: RoutePath.FINANCE,
    icon: BanknotesIcon,

    children: [
      {name: t`Account Activity`, href: RoutePath.FINANCE_ACCOUNT_ACTIVITY},
      {name: t`Payouts`, href: RoutePath.FINANCE_PAYOUTS},
    ],
  },
  {
    name: t`Reports`,
    href: RoutePath.REPORTS,
    icon: PresentationChartLineIcon,
    parentPageExist: true,
    children: [
      {name: t`Transaction Profitability`, href: RoutePath.REPORTS_PROFITABILITY},
      ...(showInterchangeReportPage
        ? [{name: t`Interchange Report`, href: RoutePath.REPORTS_INTERCHANGE}]
        : []),
      {name: t`Other Revenue Report`, href: RoutePath.REPORTS_OTHER_REVENUE},
      ...(import.meta.env.VITE_SHOW_IN_PROGRESS
        ? [
            {name: t`Risk Report`, href: RoutePath.REPORTS_RISK, limited: true},
            {name: t`Fees Report`, href: RoutePath.REPORTS_FEES, limited: true},
            {
              name: t`Monthly Perfomance`,
              href: RoutePath.REPORTS_MONTH_TO_MONTH_PERFOMANCE,
              limited: true,
            },
            {
              name: t`Effective Discount Rate`,
              href: RoutePath.REPORTS_EFFECTIVE_DISCOUNT_RATE,
              limited: true,
            },
            {
              name: t`Account Perfomance`,
              href: RoutePath.REPORTS_EFFECTIVE_DISCOUNT_RATE,
              limited: true,
            },
            {
              name: t`Chargeback Report`,
              href: RoutePath.REPORTS_EFFECTIVE_DISCOUNT_RATE,
              limited: true,
            },
          ]
        : []),
    ],
  },
  {
    name: t`Risk`,
    href: RoutePath.RISK,
    icon: ShieldCheckIcon,
    children: [
      {
        name: t`Merchants Stopped Processing`,
        href: RoutePath.RISK_MERCHANT_STOPPED_PROCESSING,
      },
      {
        name: t`Merchants Credit Card Chargebacks`,
        href: RoutePath.RISK_MERCHANT_CHARGREBACKS,
      },
    ],
  },
  ...(enableTerminals
    ? [
        {
          name: t`Terminals`,
          href: RoutePath.TERMINALS,
          icon: BuildingStorefrontIcon,
          children: [{name: t`Manage`, href: RoutePath.TERMINALS_MANAGE}],
        },
      ]
    : []),
  {
    name: t`Users`,
    href: RoutePath.USERS,
    icon: UserIcon,
    children: [
      {name: t`Active`, href: RoutePath.ACTIVE_USERS},
      {name: t`Invited`, href: RoutePath.INVITED_USERS},
    ],
  },
  {
    name: t`Configure`,
    href: RoutePath.CONFIGURE,
    icon: Cog6ToothIcon,

    children: [
      {name: t`Fee Groups`, href: RoutePath.FEE_GROUPS},
      {name: t`Brand`, href: RoutePath.BRAND},
      {name: t`Webhooks`, href: RoutePath.WEBHOOKS},
      {name: t`API Keys`, href: RoutePath.API_KEYS},
    ],
  },
  {
    name: t`Developers`,
    icon: CodeBracketIcon,
    href: import.meta.env.VITE_DOCS_URL ?? 'https://docs.zentact.com',
    newTab: true,
  },
];

export const userNavigation = (
  setSelectedPspMerchantAccountName: (name: string) => void,
  selectedPspMerchantAccountName?: string,
  pspMerchantAccounts?: {
    pspMerchantAccountName: string;
    displayName: string | null;
    countryCode: string;
    currency: string;
  }[]
) => [
  ...(pspMerchantAccounts ?? []).map(account => ({
    name:
      (account.displayName || account.pspMerchantAccountName) +
      (selectedPspMerchantAccountName === account.pspMerchantAccountName ? ' ✓' : ''),
    onClick: () => {
      setSelectedPspMerchantAccountName(account.pspMerchantAccountName);
    },
  })),
  {name: t`Sign out`, href: RoutePath.USER_LOGOUT},
];
