import {trpc} from '@/api/trpcClient';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {OrganizationListItem} from '@zentact/api/src/trpc/routers/organizationRouter';
import {ErrorCode, OrganizationPublicStatus, isFormattedTrpcError} from '@zentact/common';
import {
  AlertOverlayWithConfirmation,
  DropDownMinimalMenuIcon,
  InputText,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {OrganizationNameData, getOrganizationNameSchema} from '../edit-organization-name';

type Props = {
  refetchOrganizationList: () => void;
  viewType: 'dots-button' | 'chevron-button';
  organizationRow: OrganizationListItem;
  openDetailsPanel: (row: OrganizationListItem) => void;
};

const statusToItemsMap = (
  action: (action: 'deactivate' | 'reactivate' | 'rename' | 'details') => void,
  hasNonArchivedMerchants: boolean
): Record<
  OrganizationPublicStatus,
  {name: string; onClick: () => void; itemClassName?: string}[]
> => {
  return {
    [OrganizationPublicStatus.ACTIVE]: [
      {name: t`View Details`, onClick: () => action('details')},
      {name: t`Rename`, onClick: () => action('rename')},
      ...(!hasNonArchivedMerchants
        ? [
            {
              name: t`Deactivate`,
              onClick: () => action('deactivate'),
              itemClassName: 'text-red-500',
            },
          ]
        : []),
    ],
    [OrganizationPublicStatus.DEACTIVATED]: [
      {
        name: t`Reactivate`,
        onClick: () => action('reactivate'),
      },
    ],
  };
};

export const OrganzationActions = (props: Props) => {
  const {organizationRow, refetchOrganizationList, viewType, openDetailsPanel} = props;
  const [deactivateConfirmationOpen, setDeactivateConfirmationOpen] = useState(false);
  const [renameOrganizationConfirmationOpen, setRenameOrganizationConfirmationOpen] =
    useState(false);

  const {showSuccessNotification, showErrorNotification} = useNotification();

  const form = useForm<OrganizationNameData>({
    resolver: zodResolver(getOrganizationNameSchema()),
    defaultValues: {
      organizationName: organizationRow.name,
    },
  });
  const {
    register,
    formState: {errors},
    handleSubmit,
    setError,
    clearErrors,
    reset,
  } = form;

  useEffect(() => {
    reset({organizationName: organizationRow.name});

    if (!open) {
      clearErrors();
    }
  }, [organizationRow, open]);

  const deactivateMutation = trpc.organization.updateOrganizationStatus.useMutation({
    onSuccess: () => {
      refetchOrganizationList();
      setDeactivateConfirmationOpen(false);
      showSuccessNotification(
        t`Organization deactivated`,
        t`You have successfully deactivated ${organizationRow.name}.`
      );
    },
  });

  const reactivateMutation = trpc.organization.updateOrganizationStatus.useMutation({
    onSuccess: () => {
      refetchOrganizationList();
      showSuccessNotification(
        t`Organization reactivated`,
        t`You have successfully reactivated ${organizationRow.name}.`
      );
    },
  });

  const updateOrganizationName = trpc.organization.updateOrganizationName.useMutation({
    onSuccess: () => {
      showSuccessNotification(
        t`Organization name updated`,
        t`The organization name has been updated.`
      );
      setRenameOrganizationConfirmationOpen(false);
      refetchOrganizationList();
      reset();
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      if (errorCode === ErrorCode.ORGANIZATION_ALREADY_EXISTS) {
        setError('organizationName', {
          type: 'manual',
          message: t`An organization with that name already exists, please choose a unique name.`,
        });

        return;
      }

      showErrorNotification(
        t`Failed to update organization name`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const onSubmit = useCallback(
    (data: OrganizationNameData) => {
      updateOrganizationName.mutate({
        newOrganizationName: data.organizationName,
        pspMerchantAccountName: organizationRow.pspMerchantAccountName,
        organizationId: organizationRow.id,
      });
    },
    [organizationRow]
  );

  const handleAction = (action: string) => {
    if (action === 'details') {
      openDetailsPanel(organizationRow);
    }
    if (action === 'rename') {
      setRenameOrganizationConfirmationOpen(true);
    }
    if (action === 'deactivate') {
      setDeactivateConfirmationOpen(true);
    }
    if (action === 'reactivate') {
      reactivateMutation.mutate({
        organizationId: organizationRow.id,
        status: OrganizationPublicStatus.ACTIVE,
      });
    }
  };

  const handleDeactivate = useCallback(() => {
    deactivateMutation.mutate({
      organizationId: organizationRow.id,
      status: OrganizationPublicStatus.DEACTIVATED,
    });
  }, [organizationRow]);

  const menuItems = statusToItemsMap(handleAction, !!organizationRow.nonArchivedMerchansCount)[
    organizationRow.status
  ];
  if (!menuItems) return null;

  return (
    <>
      <DropDownMinimalMenuIcon
        items={menuItems}
        buttonContent={viewType === 'chevron-button' ? <Trans>Actions</Trans> : undefined}
      />
      {deactivateConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={deactivateConfirmationOpen}
          setOpen={setDeactivateConfirmationOpen}
          handleAction={handleDeactivate}
          localeText={{
            title: t`Deactivate merchant`,
            description: t`Are you sure you want to deactivate ${organizationRow.name}?`,
            confirm: t`Deactivate`,
            cancel: t`Cancel`,
          }}
        />
      )}
      {renameOrganizationConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={renameOrganizationConfirmationOpen}
          setOpen={setRenameOrganizationConfirmationOpen}
          disableConfirmButton={false}
          handleAction={handleSubmit(onSubmit)}
          mode="error"
          localeText={{
            title: t`Are you sure you want to rename this organization?`,
            description: t`Renaming this will change the name across the experience for all users.`,
            confirm: t`Rename`,
            cancel: t`Cancel`,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} className="px-4 pb-4 bg-white">
            <div className="pb-2 mt-2">
              <p className="text-sm">Organization Name</p>
            </div>
            <div className="">
              <InputText
                {...register('organizationName', {required: true})}
                hasError={Boolean(errors.organizationName)}
                placeholder={t`e.g Acme Corporation`}
              />
            </div>
            <ValidationError isVisible={Boolean(errors.organizationName)}>
              {errors.organizationName?.message}
            </ValidationError>
          </form>
        </AlertOverlayWithConfirmation>
      )}
    </>
  );
};
