import {CurrencyCode, LocaleCode} from '../../constants';

/**
 * Retrieves the currency symbol for the given currency code and locale.
 * It formats the number zero in the given locale and currency, then strips out any numeric and space characters,
 * leaving just the currency symbol. This method assumes that the currency symbol is not surrounded by digits
 * or common separators like spaces, commas, or periods.
 *
 * @param {CurrencyCode} currency - The currency code to retrieve the symbol for.
 * @param {LocaleCode} [locale=LocaleCode.en_US] - The locale to use for formatting the currency.
 * @returns {string} The currency symbol.
 *
 * @example
 * // Returns '$' for US dollars in US locale
 * getCurrencySymbol('USD', 'en-US');
 *
 * @example
 * // Returns '€' for Euros in German locale
 * getCurrencySymbol('EUR', 'de-DE');
 */

export const getCurrencySymbol = (
  currency: CurrencyCode,
  locale: LocaleCode = LocaleCode.en_US
): string => {
  const formatted = (0).toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatted.replace(/[\d\s,.]+/g, '').trim();
};
