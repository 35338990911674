import {t} from '@lingui/macro';
import {TerminalsOutput} from '@zentact/api/src/trpc/routers/terminalRouter';
import {ExportToCsv} from '@zentact/ui-tailwind';

export const exportToCsvTerminals = (fullTerminalsList: TerminalsOutput) => {
  const exporter = new ExportToCsv({
    filename: `terminals-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!fullTerminalsList.rows.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    fullTerminalsList.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`PSP Merchant Account`]: row.pspMerchantAccountName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Organization`]: row.organizationName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Account`]: row.businessName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`ID`]: row.id,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Status`]:
        !!row.status &&
        row.status
          .replace(/([A-Z])/g, ' $1')
          .replace(/\b\w/g, char => char.toUpperCase())
          .trim(),
    }))
  );
};
