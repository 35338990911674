import {trpc} from '@/api/trpcClient';
import {useSplitConfigurationGroups} from '@/hooks';
import {
  OrganizationForm,
  OrganizationFormData,
  getOrganizationFormSchema,
} from '@/pages/customers/organization-form/organization-form';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {ErrorCode, isFormattedTrpcError} from '@zentact/common';
import {Button, SlideOverWithBrandedHeader, useNotification} from '@zentact/ui-tailwind';
import {useCallback, useEffect} from 'react';
import {DeepPartial, useForm} from 'react-hook-form';

type InviteMerchantProps = {
  isOpen: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  organizationId?: string | null;
  organizationFormDefaultValue?: DeepPartial<OrganizationFormData> | null;
};

export const InviteMerchant = ({
  isOpen,
  onSuccess,
  onCancel,
  organizationId,
  organizationFormDefaultValue,
}: InviteMerchantProps) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {splitConfigurationGroups, defaultSplitConfigurationGroup} = useSplitConfigurationGroups();
  const {refetchOrganizationList, tenant, pspMerchantAccountName} = useStore();
  const form = useForm<OrganizationFormData>({
    resolver: zodResolver(getOrganizationFormSchema(tenant.features?.merchantReferenceIdRequired)),
    defaultValues: organizationFormDefaultValue ?? {
      organization: {
        organizationType: 'new',
      },
      splitConfigurationGroupId: defaultSplitConfigurationGroup?.id,
    },
  });
  const splitConfigurationGroupId = form.watch('splitConfigurationGroupId');

  useEffect(() => {
    if (splitConfigurationGroupId || !defaultSplitConfigurationGroup) {
      return;
    }

    form.setValue('splitConfigurationGroupId', defaultSplitConfigurationGroup.id);
  }, [splitConfigurationGroupId, defaultSplitConfigurationGroup]);

  const {handleSubmit, setError} = form;

  const inviteOrganization = trpc.organization.inviteOrganization.useMutation({
    onSuccess: () => {
      showSuccessNotification(t`Merchant invite sent`, t`The organization has been created.`);
      onSuccess();
      form.reset();
      refetchOrganizationList();
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      if (errorCode === ErrorCode.ORGANIZATION_ALREADY_EXISTS) {
        setError('organization.organizationName', {
          type: 'manual',
          message: t`Organization with the same name already exists`,
        });

        return;
      }

      if (errorCode === ErrorCode.USER_BELONGS_TO_ANOTHER_ORGANIZATION) {
        setError('merchantEmail', {
          type: 'manual',
          message: t`User already belongs to another organization`,
        });

        return;
      }

      if (errorCode === ErrorCode.MERCHANT_REGISTRATION_WITH_EMAIL_ALREADY_EXISTS) {
        setError('merchantEmail', {
          type: 'manual',
          message: t`Merchant with the same email already exists`,
        });

        return;
      }

      if (
        errorCode === ErrorCode.MERCHANT_ACCOUNT_ALREADY_REGISTERED ||
        errorCode === ErrorCode.MERCHANT_REGISTRATION_ALREADY_EXISTS
      ) {
        setError('merchantName', {
          type: 'manual',
          message: t`Merchant with the same name already exists`,
        });

        return;
      }

      if (errorCode === ErrorCode.MERCHANT_REFERENCE_ID_ALREADY_EXISTS) {
        setError('merchantReferenceId', {
          type: 'manual',
          message: t`Merchant Reference ID already exists`,
        });

        return;
      }

      showErrorNotification(
        t`Failed to invite organization`,
        t`Something went wrong. Please try again later.`
      );
    },
  });
  const inviteMerchant = trpc.merchantAccount.inviteMerchantToSelectedOrganization.useMutation({
    onSuccess: () => {
      showSuccessNotification(t`Merchant invite sent`, t`The merchant has been invited.`);
      onSuccess();
      form.reset();
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      if (errorCode === ErrorCode.ORGANIZATION_EMAIL_ALREADY_EXISTS) {
        setError('merchantEmail', {
          type: 'manual',
          message: t`Organization with the same email already exists`,
        });

        return;
      }

      if (errorCode === ErrorCode.USER_BELONGS_TO_ANOTHER_ORGANIZATION) {
        setError('merchantEmail', {
          type: 'manual',
          message: t`User already belongs to another organization`,
        });

        return;
      }

      if (errorCode === ErrorCode.MERCHANT_REFERENCE_ID_ALREADY_EXISTS) {
        setError('merchantReferenceId', {
          type: 'manual',
          message: t`Merchant Reference ID already exists`,
        });

        return;
      }

      if (
        errorCode === ErrorCode.MERCHANT_ACCOUNT_ALREADY_REGISTERED ||
        errorCode === ErrorCode.MERCHANT_REGISTRATION_ALREADY_EXISTS
      ) {
        setError('merchantName', {
          type: 'manual',
          message: t`Merchant with the same name already exists`,
        });

        return;
      }

      showErrorNotification(
        t`Failed to invite merchant`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const onSubmit = useCallback(
    (data: OrganizationFormData) => {
      if (!pspMerchantAccountName) {
        console.error('PSP merchant account name is not selected');
        return;
      }

      if (data.organization.organizationType === 'new') {
        inviteOrganization.mutate({
          organizationName: data.organization.organizationName,
          organizationSupportEmail: data.organization.organizationSupportEmail?.length
            ? data.organization.organizationSupportEmail
            : undefined,
          merchantName: data.merchantName,
          merchantEmail: data.merchantEmail,
          merchantReferenceId: data.merchantReferenceId,
          splitConfigurationGroupId: data.splitConfigurationGroupId,
          pspMerchantAccountName,
        });
      } else {
        inviteMerchant.mutate({
          merchant: {
            businessName: data.merchantName,
            merchantReferenceId: data.merchantReferenceId,
          },
          inviteeEmail: data.merchantEmail,
          organizationId: data.organization.organizationId,
          splitConfigurationGroupId: data.splitConfigurationGroupId,
          pspMerchantAccountName,
        });
      }
    },
    [pspMerchantAccountName]
  );

  const onCancelClick = useCallback(
    (_event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onCancel();
      form.reset();
    },
    [onCancel]
  );

  useEffect(() => {
    form.reset({
      splitConfigurationGroupId: defaultSplitConfigurationGroup?.id,
    });
  }, [form, defaultSplitConfigurationGroup]);

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={t`Invite a Merchant`}
      text={t`Help a merchant get started by inviting the first user to the account. From there, the user will be guided through the onboarding process.`}
      closeHandler={onCancelClick}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onSubmit)}
              isLoading={inviteOrganization.isLoading || inviteMerchant.isLoading}
            >
              <Trans>Send Invite</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onCancelClick}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <OrganizationForm
          form={form}
          organizationId={organizationId}
          splitConfigurationGroups={splitConfigurationGroups ?? []}
          merchantReferenceIdRequired={tenant.features?.merchantReferenceIdRequired}
        />
      </form>
    </SlideOverWithBrandedHeader>
  );
};
